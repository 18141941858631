import PropTypes from 'prop-types';
import { Lifecycle } from '@srpatx/scrapbook-browser';
import { Link } from 'carbon-components-react';

function AdminNav({ root }) {
  return (
    <nav>
      <ul>
        <li>
          <Link href={root.resourceHref('energyLink')}>Energy Link</Link>
        </li>
      </ul>
    </nav>
  );
}

AdminNav.propTypes = {
  root: PropTypes.object.isRequired,
};

export function Page({ controller }) {
  const session = controller.root.session;

  return (
    <Lifecycle controller={controller}>
      <h1>Revenue Quest API</h1>
      <p>Hello {session.email}</p>

      {session.admin && (
        <>
          <h2>Admin</h2>
          <AdminNav root={controller.root} />
        </>
      )}
    </Lifecycle>
  );
}

Page.propTypes = {
  controller: PropTypes.object.isRequired,
};
