import '../assets/style';
import { initializeHttp, Mixpanel } from '@srpatx/scrapbook-core';
import { csrfToken, ready, Stage } from '@srpatx/scrapbook-browser';
import { start as activeStorageStart } from '@rails/activestorage';
import { buildController } from './controllers/app';
import { Root } from './models/root';
import { Page } from './views/app/page';
import routes from './routes';
import ujs from '@rails/ujs';

ujs.start();

const title = 'Databasin';

ready(() => {
  initializeHttp({ csrfToken });

  activeStorageStart();

  const $el = document.querySelector('[revenue-quest-api-stage]');

  const analytics = new Mixpanel({
    trackingId: window.MIXPANEL_TRACKING_ID,
    debug: window.MIXPANEL_DEBUG,
  });

  const $template = document.querySelector('[revenue-quest-api-template]');
  const Controller = buildController($template.innerHTML);

  const stage = new Stage({
    $el,
    Controller,
    Page,
    Root,
    title,
    routes,
    analytics,
  });
  stage.render();
});
