import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Page as RootPage } from '../root/page';
import routes from '../../routes';

export function Router({ app }) {
  return (
    <Switch>
      <Route exact path={routes.rootPath}>
        <RootPage controller={app} />
      </Route>
      <Route path="*">
        <div dangerouslySetInnerHTML={{ __html: app.serverHtml }} />
      </Route>
    </Switch>
  );
}

Router.propTypes = {
  app: PropTypes.object.isRequired,
};
