import PropTypes from 'prop-types';
import { AppPage } from '@srpatx/scrapbook-browser';
import { Router } from './router';

export function Page({ controller }) {
  return <AppPage controller={controller} Router={Router} />;
}

Page.propTypes = {
  controller: PropTypes.object.isRequired,
};
