import { AppController } from '@srpatx/scrapbook-browser';

let serverHtml = '';

class App extends AppController {
  constructor(frame, Root) {
    super(frame, Root);
  }

  async setUp(location) {
    await super.setUp(location);
    this._render();
  }

  get serverHtml() {
    return serverHtml;
  }
}

export function buildController(html) {
  serverHtml = html;
  return App;
}
